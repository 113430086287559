* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Skranji-regular';
    src: url('../fonts/Skranji.woff2') format('woff2'),
        url('../fonts/Skranji.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Skranji-bold';
    src: url('../fonts/Skranji-Bold.woff2') format('woff2'),
        url('../fonts/Skranji-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Skranji-regular';
    background-repeat: no-repeat;
    background-color: #460000;
}

.top-header {
    padding-top: 20px;
}

header.top-header .navbar-nav.d-flex.align-items-center .nav-item .nav-link {
    color: #fff;
    font-size: 16px;
    font-family: 'Skranji-regular';
    line-height: 24px;
    padding: 0px;
}

.top-header .navbar-nav.d-flex.align-items-center .nav-item .nav-link.active {
    color: #FFD700;
    border-bottom: 2px solid;
}

.top-header .navbar-nav.d-flex.align-items-center {
    gap: 40px;
}

.top-header .navbar-expand-sm .navbar-collapse {
    column-gap: 40px;
}

.top-header .button .top-button {
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%);
    padding: 4px 20px 4px 20px;
    border-radius: 50px;
    border: none;
    color: #fff;
}

.top-section {
    background-image: url("../images/topimage.png");
    background-size: cover;
    height: 1245px;
    margin-top: -290px;
    background-repeat: no-repeat;
    background-position: top;
}

.top-section .row {
    width: 100%;
}

.top-section .row .kabby {
    background: linear-gradient(180deg, #FFD700 13.79%, #DE4300 70.69%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-family: 'Skranji-bold';
    font-size: 80px;
    line-height: 87.80px;
    letter-spacing: 0.2px;
}

.top-section .row p {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
}

.top-section .row .button-text {
    display: flex;
    gap: 30px;
}

.top-section .row .button-text .tokeno,
.top-button {
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%);
    padding: 4px 20px 4px 20px;
    border-radius: 50px;
    text-decoration: none;
    color: #fff;
    line-height: 24px;
    font-size: 16px;
}

.top-section .row .button-text .Roadmaps {


    padding: 4px 20px 4px 20px;
    text-decoration: none;
    color: #fff;
    line-height: 24px;
    font-size: 16px;
}

.top-section .row .button-text .Roadmaps {
    position: relative;

}

.join p {
    margin-bottom: 5px;
    background: linear-gradient(180deg, #FBB625 11.53%, #DE4300 74.78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.top-section .row .button-text .Roadmaps:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.top-section .row .boxed {
    box-shadow: 0px 0px 44px 0px #DE43004D;
}

.boxed {
    position: relative;
    padding: 1.3rem;
    backdrop-filter: blur(10px);
    z-index: 4;
}

.boxed:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;
}

.progress.pogress-half .progress-bar {
    background: linear-gradient(90deg, #FBB625 0%, #DE4300 100%);
    border-radius: 50px;
}

.progress.pogress-half {
    border-radius: 50px;
    margin-bottom: 20px;
}

.top-section .row .boxed {
    box-shadow: 0px 0px 44px 0px #DE43004D;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    margin-top: 253px;
}

.top-section .row p.text-days {
    color: #FBB625;
}

.buy-button .link-buy {
    width: 100%;
    display: block;
    text-align: center;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 28px;
    padding: 4px 20px 4px 20px;
    text-decoration: none;
    color: #fff;
    line-height: 24px;
    font-size: 16px;
}

.buy-button .link-buy {
    position: relative;
}

.buy-button .link-buy:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.top-section .row .boxed .current {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 19.2px;
}

.top-section .row .boxed p.stage {
    font-size: 16px;
    line-height: 28.8px;
}

.wallet {
    background: #720000;
    border-radius: 20px;
    padding: 20px;
}

.wallet .bnb-borders {
    position: relative;
}

.wallet .bnb-borders:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;

}

.wallet .wallet-border {
    border: 1px solid #FFFFFF;
    border-radius: 5px;
}

.wallet .row.main-rows-wallet {
    row-gap: 20px;
    column-gap: 10px;
}

.wallet .row.main-rows-wallet p {
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 24px;
}

.wallet .row.main-rows-wallet .col-sm-6 {
    padding: 10px;
}

.wallet .row.main-rows-wallet .col-lg-6.col-sm-4 .row {
    padding: 6px 0px;
}

.rpize p.text-center {
    margin-top: 20px;
}

.your-wallet-part {
    box-shadow: 0px 0px 22px 0px #DE430033;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    padding: 20px;
}

.wallet .your-wallet-part .text-center {
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 24px;
}

.wallet .amount-bnb {
    background: #920000;
    border-radius: 10px;
    padding: 10px 20px;
}

.wallet .amount-bnb p {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 0px;
}

a.top-button {
    width: 100%;
    display: block;
}

.button-rows .col-lg-12.col-sm-6 {
    padding: 0px;
}

.button-rows .col-lg-12.col-sm-6 .top-button {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    background: linear-gradient(0deg, #DE4300 -11.11%, #FBB625 100%);
}

.top-section .row.timer h4,
.top-section .row.timer p {
    font-size: 15px;
    margin-bottom: 0px;
    line-height: 24px;
    color: #FBB625;
}

.top-section .rpize .text-center {
    font-size: 18px;
}

div#top {
    position: relative;
}

div#top div#join-category {

    position: absolute;
    top: 37.70rem;
}

.bottom-image-section {
    background-image: url("../images/krabs-pimge.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.text-start {
    text-align: left !important;
    width: 100%;
    background: #920000;
    border: #920000;
    color: #fff;
}

.around_social {
    align-items: center;
    background-color: gold;
    border-radius: 50%;
    display: flex;
    height: 20px;
    justify-content: center;
    padding: 20px;
    width: 20px;
    margin: 0 0 0 2px;
}

.around_social a {
    color: #fff;
}

@media (min-width:1200px) {
    .wallet .row.main-rows-wallet .col-lg-6 {
        width: 48%;
    }

    .image-box.mt-3 .col-md-6.col-sm-6 {
        width: 48.94%;
    }

    .buy-purchage .row.gap-4 .col-md-4 {
        width: 31.92%;
    }

    .testimonila-section .col-sm-1.col-md-1 {
        padding: 0px;
        width: 4%;
    }
}

.bottom-image-section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.bottom-image-section .top-whitepaper .col-md-5.col-sm-6,
.second-box .col-md-4.col-sm-4,
.buy-purchage .col-md-4 {
    background: #5E0101;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    z-index: 1;
}

.bottom-image-section .top-whitepaper .col-md-5.col-sm-6:before,
.second-box .col-md-4.col-sm-4:before,
.buy-purchage .col-md-4:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;

}

.bottom-image-section .top-whitepaper .col-md-5.col-sm-6 .white-paper {
    margin-bottom: 8px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 24px;
    line-height: 28.8px;
}

.bottom-image-section .top-whitepaper .col-md-5.col-sm-6 .undetaking {
    margin-bottom: 0px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}

.second-box .col-md-4.col-sm-4 .price-paper {
    font-family: 'Skranji-bold';
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 8px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text
}

.second-box .col-md-4.col-sm-4 .text-center.text-cion {
    color: #fff;
    margin-bottom: 0px;
    line-height: 24px;
}

.third-box .secure {
    font-size: 48px;
    margin-bottom: 0px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    line-height: 76.8px;
}

.third-box .border-text {
    color: #fff;
    line-height: 24px;
}

.image-box img {
    width: 100%;
}

.buy-purchage .buy-scres {
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    line-height: 76.8px;
    font-size: 58px;
    line-height: 57.6px;
}

.buy-purchage.mt-5 {
    color: #fff;
}

.buy-purchage .buy-scres-text {
    font-size: 20px;
    padding: 0 80px;
}

.buy-purchage .col-md-4 {
    text-align: center;
}

.buy-purchage .col-md-4 .connect-text,
.roadmap .roadmap-text,
.roadmap .row .pahse-text {
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 48px;
    margin-bottom: 4px;
    line-height: 57.7px;
}

.buy-purchage .col-md-4 .download-malket {
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 40px;
}

.buy-purchage .col-md-4:nth-of-type(1) {
    background-image: url(../images/Healthinspector.png);
    background-size: cover;
    background-repeat: no-repeat;

}

.buy-purchage .col-md-4:nth-of-type(2) {
    background-image: url(../images/Healthinspector1.png);
    background-size: cover;
    background-repeat: no-repeat;

}

.buy-purchage .col-md-4:nth-of-type(3) {
    background-image: url(../images/Healthinspector2.png);
    background-size: cover;
    background-repeat: no-repeat;

}

.roadmap .row .ethereum {
    color: #fff;
    line-height: 24px;
    font-size: 20px;
}

.roadmap .vector-image {
    width: 100%;
}

.roadmap.mt-5 img {
    width: 100%;
}

.roadmap .roadmap-text {
    font-family: 'Skranji-bold';
}

.testimonila-section .left-setion span {
    font-size: 24px;
    line-height: 28.8px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.testimonila-section .left-setion p {
    font-size: 39.84px;
    line-height: 48px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.testimonila-section .left-setion .testtimonila-cions {
    display: flex;
    column-gap: 10px;
}

.testimonila-section .left-setion .testtimonila-cions img {
    width: auto;
}

.testimonila-section .right-section .col-sm-5.col-md-5 {
    position: relative;
    background: #5E0101;
    border-radius: 20px;
    z-index: 1;
}

.testimonila-section .testimonila-data .col-sm-5.col-md-5:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;
}

.testimonila-section .testimonila-data .col-sm-5.col-md-5 {
    background-image: url(../images/vector-mask.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
}

.svg-icons {
    width: 100%;
    text-align: right;
}

.testimonila-section .testimonila-data .percent-text {
    font-size: 32px;
    line-height: 38.4px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-bottom: 0px;
}

.testimonila-section .testimonila-data .percent {
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0px;
}

.testimonila-section .testimonila-data .col-sm-5.col-md-5 .text-main {
    color: #fff;
    font-size: 14px;
    font-family: 'Skranji-regular';
}

.text-box-section {
    position: relative;
    background: #5E0101;
    border-radius: 20px;
    padding: 20px;
    z-index: 1;
}

.text-box-section:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;

}

.text-box-section .heading-text {
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-bottom: 0px;
    font-size: 39.84px;
    line-height: 48px;
    margin-bottom: 8px;
}

.text-box-section p {
    color: #fff;
    line-height: 24px;
}

.text-box-section .learn-more {
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%);
    padding: 4px 20px 4px 20px;
    border-radius: 50px;
    text-decoration: none;
    color: #fff;
    line-height: 24px;
    font-size: 16px;
    display: inline-block;
}

.text-box-section .top-text {
    margin-bottom: 8px;
    font-size: 22px;
    line-height: 23.04px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.tokenomics .toke-text {
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 28.8px;
}

.tokenomics .token-info {
    font-size: 58px;
    line-height: 57.6px;
    font-family: 'Skranji-bold';
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.tokenomics .progress-bar-section,
.faq-section .accordion-item {
    position: relative;
    background: #5E0101;
    border-radius: 20px;
    padding: 20px;
    z-index: 1;
}

.tokenomics .progress-bar-section:before,
.faq-section .accordion-item:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;
}

.tokenomics .progress-bar-section .presale {
    font-size: 22.4px;
    margin-bottom: 0px;
    line-height: 26.88px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.tokenomics .progress-bar-section .facilitates {
    margin-bottom: 0px;
    color: #fff;
    line-height: 24px;
}

.tokenomics .progress-bar-section .rupess {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28.8px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.tokenomics .progress-bar-section .rupess .rupe-text {
    color: #fff;
    font-size: 16px;
    line-height: 19.2px;
    -webkit-text-fill-color: white;
}

.tokenomics .progress-bar-section .progress.pogress-half {
    margin-bottom: 0px;
}

.faq-section .faq-text {
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 58px;
    line-height: 57.6px;
    font-family: 'Skranji-bold';

}

.faq-section .accordion-item {
    margin-bottom: 40px;
    border: none;
}

.faq-section .accordion.accordion-flush button.accordion-button.collapsed {
    padding: 0px;
    background-color: #ffffff00;
}

.faq-section .accordion-button:not(.collapsed) {
    color: #052c65;
    background-color: #54657e00;

}

.faq-section .accordion-button:focus {
    box-shadow: none;
}

.faq-section .accordion-button:not(.collapsed) {
    background-color: #54657e00;
    padding: 0px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 22.4px;
    line-height: 26.88px;
}

.faq-section .accordion.accordion-flush button.accordion-button.collapsed {
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 22.4px;
    line-height: 26.88px;

}

.faq-section .accordion-item .accordion-body {
    color: #fff;
    padding: 0px;
    font-size: 16px;
    line-height: 24px;
    padding-right: 160px;
    padding-top: 10px;
}

.faq-section .accordion-flush>.accordion-item>.accordion-header .accordion-button {
    box-shadow: none;
}

.accordion-button.collapsed::after {
    background-image: url(../images/plus.png);
    background-repeat: no-repeat;
    background-position: center;
}

.accordion-button[aria-expanded="true"]::after {
    background-image: url(../images/minus.png);
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;


}

.bottom-footer {
    position: relative;
}

.bottom-footer:before {
    content: "";
    position: absolute;
    inset: 0;
    border-top: 1.5px solid transparent;
    background: linear-gradient(180deg, #FFD700 0%, #DE4300 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;

}

.bottom-footer .copy-right {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 26.88px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.bottom-footer .join-footer {
    margin-bottom: 6px;
    font-size: 18px;
    line-height: 26.88px;
    background: linear-gradient(180deg, #FBB625 10.42%, #DE4300 76.39%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.social-icons {
    display: flex;
}

.bottom-footer .social-icons {
    padding-right: 80px;
    float: right;
}

@media (min-width:1200px) {
    .second-box .col-md-4.col-sm-4 {
        width: 31.90%;
    }

}

/*----mobile view----*/
@media (max-width:767px) {
    .navbar-toggler {
        border: none;
    }

    .navbar-toggler .navbar-toggler-icon {
        color: red;
        background-color: #fed300;
        border-radius: 4px;
        width: 2.5rem;
        height: 2.5rem;
    }

    .top-section .row .kabby {
        font-size: 38px;
        line-height: 44.8px;
        letter-spacing: 0.2px;
    }

    div#top div#join-category {
        padding-top: 30px;
    }

    .top-section .row .boxed {
        margin-top: 30px;
    }

    .top-section {
        height: auto;
        margin-top: auto;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .top-section .row .button-text {
        justify-content: center;
    }

    .top-section .row {
        width: auto;
    }

    div#top div#join-category {
        padding-top: 30px;
        position: initial;
    }

    .top-section .row .boxed .col-sm-6 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .timer .col-sm-3 {
        width: 50%;
    }

    .bottom-image-section {
        padding-left: 13px;
        padding-right: 13px;
    }

    .third-box .secure {
        text-align: center !important;
    }

    .buy-purchage .buy-scres {
        font-size: 38px;
        line-height: 33.6px;
    }

    .buy-purchage .buy-scres-text {
        padding: 0 0px;
    }

    .buy-purchage .col-md-4 .connect-text,
    .roadmap .roadmap-text,
    .roadmap .row .pahse-text {
        font-size: 36px;

    }

    .roadmap.mt-5 .row.align-items-center {
        flex-direction: column-reverse;
    }

    .testimonila-section {
        text-align: center;
    }

    .testimonila-section .left-setion p {
        font-size: 32.84px;
        line-height: 42px;
    }

    .testimonila-section .left-setion .testtimonila-cions {
        justify-content: center;
        margin-bottom: 10px;
    }

    .testimonila-section .row.testimonila-data.justify-content-end {
        gap: 10px;
    }

    .text-box-section.mt-5 .row.align-items-center {
        flex-direction: column;
        row-gap: 20px;
    }

    .text-box-section .heading-text {
        font-size: 24.84px;
        line-height: 32px;

    }

    .tokenomics .token-info,
    .faq-section .faq-text {
        font-size: 40px;
        line-height: 46.6px;
    }

    .tokenomics .progress-bar-section {
        gap: 10px;
    }

    .faq-section .accordion-item .accordion-body {
        padding-right: 0px;
    }

    .top-header .navbar-nav.d-flex.align-items-center {
        gap: 16px;
        align-items: flex-start !important;
    }

    .bottom-image-section {
        background-size: cover;
    }

    .navbar-toggler:focus {
        box-shadow: none !important;

    }
}

/*----end mobile view----*/

/*----pad device-----*/
@media (min-width:768px) and (max-width:1180px) {
    .navbar-toggler:focus {
        box-shadow: none !important;

    }

    .top-section .row .kabby {
        font-size: 38px;
        line-height: 44.8px;
        letter-spacing: 0.2px;
        text-align: left;
    }

    .top-section .row p {
        text-align: left;
    }

    div#top div#join-category {
        padding-top: 30px;
    }

    .top-section .row .boxed {
        margin-top: 30px;
    }

    .top-section {
        height: auto;
        margin-top: auto;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .top-section .row {
        width: auto;
    }

    div#top div#join-category {
        padding-top: 30px;
        position: initial;
    }

    .top-section {
        background-position: left;
    }

    .button-rows .col-lg-12.col-sm-6 {
        padding: 0px;
        width: 100%;
    }

    .second-box .col-md-4.col-sm-4 {
        width: 100%;
    }

    .image-box.mt-3 .col-md-6.col-sm-6 {
        width: 48%;
    }

    .buy-purchage .col-md-4 {
        width: 48%;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .top-header .button .top-button {

        margin-top: 10px;
    }

    .bottom-image-section {
        background-size: cover;
    }
}



.imaged {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

/*--.left-fade {
    opacity: 0;
    animation: fadeInLeft 1s ease forwards;
	overflow:hidden;
}

.right-fade {
    opacity: 0;
    animation: fadeInRight 2s ease forwards;
	overflow:hidden;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-120px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(120px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}---*/

.button-text a,
.top-section .row .button-text .tokeno,
.buy-button .link-buy,
.button-rows .col-lg-12.col-sm-6 .top-button,
.text-box-section .learn-more {

    transition: background-image 0.3s ease;
}

.button-text a:hover,
.top-section .row .button-text .tokeno:hover,
.buy-button .link-buy:hover,
.button-rows .col-lg-12.col-sm-6 .top-button:hover,
.text-box-section .learn-more:hover {
    animation: shake 0.3s ease-in-out forwards;
    /* Apply animation on hover */
    background-image: linear-gradient(180deg, #de4300 0%, #460000 100%);
    /* Gradient background color change on hover */
    border-radius: 50px;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-3px);
    }

    100% {
        transform: translateX(0);
    }
}